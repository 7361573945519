<template>
  <footer class="Footer HeavyFooter">
    <div
      v-if="$props.isPreFooterVisible"
      class="FooterTop"
    >
      <div class="Container">
        <div class="FooterTop--Wrapper">
          <section class="FooterTop--Section">
            <h2 class="FooterTop--Title">
              <strong>{{ $t('footer.title.start') }}</strong>
              {{ $t('footer.title.end') }}
            </h2>
            <div class="FooterTop--Mission">
              <p>
                {{ $t('footer.aboutHa') }}
              </p>
            </div>
            <a
              v-segment="{
                name: 'Footer Item Clicked',
                payload: {
                  item_type: 'link',
                  item_label: 'associations',
                  href: '/associations'
                }
              }"
              href="/associations"
              target="_blank"
              rel="noreferrer noopener"
              class="FooterTop--Cta"
              data-ux="Explore_Footer_Top_About"
            >
              {{ $t('footer.cta') }}
              <span class="visually-hidden">
                {{ $t('accessibility.openNewWindow') }}
              </span>
            </a>
          </section>
          <section class="FooterTop--Section">
            <ul class="FooterTop--Features">
              <li>
                <HaIcon :icon="faGlobe" />
                <div>
                  <h3 class="FooterTop--Subtitle">
                    <strong>
                      {{ $t('footer.features.alternativeTitle') }}
                    </strong>
                  </h3>
                  <p>
                    {{ $t('footer.features.alternativeText') }}
                  </p>
                </div>
              </li>
              <li>
                <HaIcon :icon="faHeart" />
                <div>
                  <h3 class="FooterTop--Subtitle">
                    <strong>
                      {{ $t('footer.features.universalTitle') }}
                    </strong>
                  </h3>
                  <p>
                    {{ $t('footer.features.universalText') }}
                  </p>
                </div>
              </li>
              <li>
                <HaIcon :icon="faUsers" />
                <div>
                  <h3 class="FooterTop--Subtitle">
                    <strong>
                      {{ $t('footer.features.humanTitle') }}
                    </strong>
                  </h3>
                  <p>
                    {{ $t('footer.features.humanText') }}
                  </p>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
    <div class="FooterBottom">
      <div class="Container">
        <div class="FooterBottom--Wrapper">
          <div class="FooterBottom--Inner">
            <ul
              v-for="column of footerLinksColumns"
              :key="column.title"
            >
              <li v-if="column.title === 'Contact'">
                <a
                  v-segment="{
                    name: 'Footer Item Clicked',
                    payload: {
                      item_type: 'logo',
                      item_label: 'logo',
                      href: '/'
                    }
                  }"
                  href="/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.helloasso.com/images/explore/logo-helloasso-white.svg"
                    :alt="$t('footer.logo')"
                    class="FooterBottom--Inner--Logo"
                  />
                  <span class="visually-hidden">
                    {{ $t('accessibility.openNewWindow') }}
                  </span>
                </a>
              </li>

              <li class="FooterBottom--Title">
                {{ column.title }}
              </li>
              <li
                v-for="link of column.links"
                :key="link.href"
              >
                <a
                  v-segment="{
                    name: 'Footer Item Clicked',
                    payload: {
                      item_type: 'link',
                      item_label: link.text,
                      href: link.href
                    }
                  }"
                  :href="link.href"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="FooterBottom--Link"
                >
                  {{ link.text }}
                  <span class="visually-hidden">
                    {{ $t('accessibility.openNewWindow') }}
                  </span>
                </a>
              </li>
              <li
                v-if="column.title === 'Contact'"
                class="FooterBottom--Inner--Icons"
              >
                <a
                  v-segment="{
                    name: 'Footer Item Clicked',
                    payload: {
                      item_type: 'button',
                      item_label: 'facebook',
                      href: 'https://www.facebook.com/helloasso/?fb_dtsg_ag=AdyJdZQCJdObalyvg7NiBKVK0vPk2ZZ8oaDHyYFr6_vHrA%3AAdxoViHrDdf8hw3Iwk20xeZF8Ez7dFJgdTL5KpABDo24LQ'
                    }
                  }"
                  href="https://www.facebook.com/helloasso/?fb_dtsg_ag=AdyJdZQCJdObalyvg7NiBKVK0vPk2ZZ8oaDHyYFr6_vHrA%3AAdxoViHrDdf8hw3Iwk20xeZF8Ez7dFJgdTL5KpABDo24LQ"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="FooterBottom--Link"
                >
                  <HaIcon :icon="faFacebookF" />
                  <span class="visually-hidden">
                    {{ $t('accessibility.openNewWindow') }}
                  </span>
                </a>
                <a
                  v-segment="{
                    name: 'Footer Item Clicked',
                    payload: {
                      item_type: 'button',
                      item_label: 'twitter',
                      href: 'https://twitter.com/helloasso'
                    }
                  }"
                  href="https://twitter.com/helloasso"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="FooterBottom--Link"
                >
                  <HaIcon :icon="faXTwitter" />
                  <span class="visually-hidden">
                    {{ $t('accessibility.openNewWindow') }}
                  </span>
                </a>
                <a
                  v-segment="{
                    name: 'Footer Item Clicked',
                    payload: {
                      item_type: 'button',
                      item_label: 'youtube',
                      href: 'https://www.youtube.com/channel/UCYz5xk40Yayisba7qTjHAdQ'
                    }
                  }"
                  href="https://www.youtube.com/channel/UCYz5xk40Yayisba7qTjHAdQ"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="FooterBottom--Link"
                >
                  <HaIcon :icon="faYoutube" />
                  <span class="visually-hidden">
                    {{ $t('accessibility.openNewWindow') }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="FooterBottom--Mentions">
            <p class="FooterBottom--Copyright">
              <!-- removing this span causes a node mismatch error, don't remove -->
              <span>© {{ new Date().getFullYear() }}</span>
              <a
                v-segment="{
                  name: 'Footer Item Clicked',
                  payload: {
                    item_type: 'link',
                    item_label: 'helloasso',
                    href: '/'
                  }
                }"
                href="/"
                target="_blank"
                rel="noreferrer noopener"
                data-ux="Explore_Footer_Bottom_BackToHome"
              >
                helloasso
                <span class="visually-hidden">
                  {{ $t('accessibility.openNewWindow') }}
                </span>
              </a>
              · {{ $t('footer.mentions.copyright') }}.
            </p>
            <div class="FooterBottom--Lemonway">
              <div class="FooterBottom--Lemonway--Images">
                <img
                  loading="lazy"
                  src="https://cdn.helloasso.com/images/explore/macaron-fpf.png"
                  :alt="$t('footer.mentions.fpfMember')"
                  :title="$t('footer.mentions.fpfMember')"
                />
                <img
                  loading="lazy"
                  src="https://cdn.helloasso.com/images/explore/macaron-marianne.png"
                  :alt="$t('footer.mentions.regulatedPlatform')"
                  :title="$t('footer.mentions.regulatedPlatform')"
                />
              </div>
              <div>
                <p>
                  {{ $t('footer.mentions.reassurance') }}
                </p>
                <p>
                  {{ $t('footer.mentions.lemonway_1') }}<br />
                  {{ $t('footer.mentions.lemonway_2') }}<br />
                  {{ $t('footer.mentions.lemonway_3') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import {
  faFacebookF,
  faXTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import {
  faGlobe,
  faHeart,
  faUsers
} from '@fortawesome/pro-solid-svg-icons'
import { useI18n } from '#imports'
import { HaIcon } from '@ha/components-v3'

defineProps({
  isPreFooterVisible: {
    type: Boolean,
    default: true
  }
})

const i18n = useI18n()

const footerLinksColumns = [
  {
    title: i18n.t('footer.link.contact'),
    links: [
      {
        text: i18n.t('footer.link.contactUs'),
        href: '/contactez-nous'
      },
      {
        text: i18n.t('footer.link.press'),
        href: '/presse'
      },
      {
        text: i18n.t('footer.link.newsletter'),
        href: '/blog/inscription-newsletter'
      }
    ]
  },
  {
    title: i18n.t('footer.link.discoverHA'),
    links: [
      {
        text: i18n.t('footer.link.jobs'),
        href: 'https://www.welcometothejungle.co/companies/helloasso/jobs'
      },
      {
        text: i18n.t('footer.link.whoAreUs'),
        href: '/qui-sommes-nous'
      },
      {
        text: i18n.t('footer.link.manifest'),
        href: '/le-manifeste'
      },
      {
        text: i18n.t('footer.link.commitments'),
        href: '/nos-engagements'
      }
    ]
  },
  {
    title: i18n.t('footer.link.help'),
    links: [
      {
        text: i18n.t('footer.link.helpCenter'),
        href: 'https://centredaide.helloasso.com/association'
      },
      {
        text: i18n.t('footer.link.resources'),
        href: '/ressources'
      },
      {
        text: i18n.t('footer.link.blog'),
        href: '/blog'
      },
      {
        text: i18n.t('footer.link.assoGroup'),
        href: 'https://www.facebook.com/groups/LeGroupeDesAssos/?locale=fr_FR'
      },

      {
        text: i18n.t('footer.link.status'),
        href: 'https://status.helloasso.com'
      },
      {
        text: i18n.t('footer.link.downloadOurApp'),
        href: 'https://onelink.to/footer_show?utm_source=footer_show&utm_medium=email'
      }
    ]
  },
  {
    title: i18n.t('footer.link.legalInfos'),
    links: [
      {
        text: i18n.t('footer.link.userCGU'),
        href: '/cgu-utilisateur'
      },
      {
        text: i18n.t('footer.link.associationCGU'),
        href: '/cgu-associations/2023-10'
      },
      {
        text: i18n.t('footer.link.legalNotice'),
        href: '/mentions-legales'
      },
      {
        text: i18n.t('footer.link.privacy'),
        href: '/confidentialite'
      },
      {
        text: i18n.t('footer.link.activitiesReport'),
        href: '/mentions-legales#AnchorActivityReports'
      },
      {
        text: i18n.t('footer.link.cookiesManagement'),
        href: 'javascript:openAxeptioCookies()'
      }
    ]
  },
  {
    title: i18n.t('footer.link.activities'),
    links: [
      {
        text: i18n.t('footer.link.workshopsAndCourses'),
        href: '/activites/atelier-stage'
      },
      {
        text: i18n.t('footer.link.competitionsAndTournaments'),
        href: '/activites/competition-tournoi'
      },
      {
        text: i18n.t('footer.link.courses'),
        href: '/activites/cours'
      },
      {
        text: i18n.t('footer.link.running'),
        href: '/activites/course-trail'
      },
      {
        text: i18n.t('footer.link.festivals'),
        href: '/activites/festival'
      },
      {
        text: i18n.t('footer.link.projectFinancing'),
        href: '/activites/financement-de-projet'
      },
      {
        text: i18n.t('footer.link.evenings'),
        href: '/activites/soiree'
      },
      {
        text: i18n.t('footer.link.showsAndConcerts'),
        href: '/activites/spectacle-concert'
      },
      {
        text: i18n.t('footer.link.raffle'),
        href: '/activites/tombola'
      }
    ]
  }
]
</script>

<style scoped lang="scss">
@import './style';
</style>
