<template>
  <div
    id="LayoutHomepage"
    class="Layout Layout-Homepage"
  >
    <HaToaster ref="haToaster" />
    <PreHeader />
    <!-- Header -->
    <HaHeaderPublic
      :config-env="configEnv"
      @connect-request="openLoginModale()"
      @disconnect="onLogOut"
      @on-search="handleSearch"
    />
    <ModalLogin
      v-if="displayModalLogin"
      @close="displayModalLogin = false"
    />
    <!-- Content -->
    <main>
      <slot />
    </main>
    <!-- Footer -->
    <HaFooter :is-pre-footer-visible="false" />
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  createError,
  useRouter,
  onBeforeMount,
  onMounted
} from '#imports'
import {
  HaToaster,
  HaHeaderPublic,
  useHeaderPublic,
  useHeaderSearch,
  useNotifications,
  useTracking
} from '@ha/components-v3'
import HaFooter from '@/components/Footer/HeavyFooter.vue'
import ModalLogin from '@/components/Modal/ModalLogin.vue'
import useLogin from '@/composables/useLogin'
import useCookies from '@/composables/useCookies'
import useConfigEnv from '@/composables/useConfigEnv'
import { isUserConnected } from '@/services/auth.service'
import useSegment from '@/composables/useSegment'
import useSearch from '~/domains/search/composables/search.composable'
import PreHeader from '@/domains/home/components/PreHeader.vue'
import { useUserStore } from '@/store/user.store'

const { tracking } = useTracking()
const { setUser, setOrganizations, setHeader } = useHeaderPublic()
const { isAllowedSearchFromHeader } = useHeaderSearch()
const { haToaster } = useNotifications()
const { onLogOut } = useLogin()
const { getAccessToken, cookies } = useCookies()
const router = useRouter()
const configEnv = useConfigEnv()
const { trackIdentify } = useSegment()
const userStore = useUserStore()

const {
  store: storeAlgolia,
  currentTabIndexes,
  setStoreToUrlQuery,
  updateQueriesAndFetch
} = useSearch()

const filtersHeader = {
  ...storeAlgolia.filters,
  query: storeAlgolia.search
}
setHeader(router, tracking, filtersHeader)

const displayModalLogin = ref(false)
onBeforeMount(async () => {
  try {
    if (isUserConnected(cookies)) {
      const token = getAccessToken()
      if (!token) {
        userStore.revokeUser()
      }
      await userStore.fetchUser()
      setUser(userStore.profile)
      trackIdentify()

      const hasUserOrganization = userStore.organizations?.length
      if (hasUserOrganization) {
        setOrganizations(userStore.organizations)
      }
    }
  } catch (err) {
    console.error(err)
    const statusCode = err.response?.status || 500
    const statusMessage = `${err.message} - ${err.response?.statusText}`
    throw createError({ statusCode, statusMessage })
  }
})

onMounted(() => {
  // not sure what this boolean is about @Lucas ?
  isAllowedSearchFromHeader.value = false
})

const openLoginModale = () => {
  displayModalLogin.value = true
}

const handleSearch = (data) => {
  storeAlgolia.setSearch(data.query)
  // to avoid to have query key in filters
  delete data.query
  // set filters from url  to storeAlgolia
  storeAlgolia.setFilters(data)
  storeAlgolia.setParams({ page: undefined })

  setStoreToUrlQuery()
  // refetch
  updateQueriesAndFetch(currentTabIndexes.value)
}
</script>

<style lang="scss">
/* refacto ?? */
@import './styles/default';
</style>
